import { Tooltip, IconButton } from '@mui/material';
import { BarChart as BarchartIcon } from '@mui/icons-material';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { proposalsExportToChartHead } from 'constants/proposalsExportToChart';
import { forEach, keyBy, mapValues, omit } from 'lodash';
import { SortOrder } from 'components/ui/Table/components/HeaderCell/HeaderCell';
import { useLoadProposals } from 'template/Proposals/hooks/loadProposals';
import { ProgressInfo } from 'components/ProgressInfo';

import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { IHeadCell } from 'components/ui/Table/components/TableHead/TableHead';
import { monetaryBreakdownExportToChartHead } from 'constants/monetaryBreakdownExportToChart';
import { prorateMonetaryBreakdown } from 'utils/monetaryBrakdown';
import { ChartingDialog } from 'template/Modals/ChartingDialog/ChartingDialog';
import { ChartingPreconfigDialog } from 'template/Modals/ChartingPreconfigDialog/ChartingPreconfigDialog';
import { Config } from 'template/Charting/context/DataSourceConfig';

const fileName = 'Proposals';

export interface IExportToChartProps {
  pageLoadParams: any;
  totalItems: number;
}

export const ExportToChart: FC<IExportToChartProps> = ({ pageLoadParams, totalItems }) => {
  const [showProgressInfo, setShowProgressInfo] = useState(false);
  const [showCharting, setShowCharting] = useState(false);
  const [showPreconfig, setShowPreconfig] = useState(false);
  const [chartData, setChartData] = useState<any[]>([]);
  const [chartConfig, setChartConfig] = useState<Config>({ columns: [] });

  const { loadProposals, loadProposalsStatus } = useLoadProposals();
  const {
    status: loadingExportDataStatus,
    stages: loadingExportDataStages,
    progress: loadingExportDataProgress,
  } = loadProposalsStatus;

  const headCells = proposalsExportToChartHead;
  const monetaryBreakdownHeadCells = monetaryBreakdownExportToChartHead;

  const order = pageLoadParams.order || SortOrder.ASC;

  const { rowsPerPage, page, orderBy, filter } = pageLoadParams;

  const variables = useMemo(() => {
    return {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderBy ? [{ column: orderBy, order: order }] : undefined,
      filter,
    };
  }, [filter, order, orderBy, page, rowsPerPage]);

  const headings = useMemo(() => {
    return mapValues(
      keyBy(
        headCells.map(({ id, label }) => ({ id, label })),
        'id'
      ),
      'label'
    );
  }, [headCells]);

  const monetaryBreakdownHeadings = useMemo(() => {
    return mapValues(
      keyBy(
        monetaryBreakdownHeadCells.map(({ id, label }) => ({ id, label })),
        'id'
      ),
      'label'
    );
  }, [monetaryBreakdownHeadCells]);

  const monetaryBreakdownHeadingStyles = useMemo(() => {
    return monetaryBreakdownHeadCells.map(({ excelHeadStyle }) => excelHeadStyle);
  }, [monetaryBreakdownHeadCells]);

  const monetaryBreakdownRowStyles = useMemo(() => {
    return monetaryBreakdownHeadCells.map(({ excelRowStyle }) => excelRowStyle);
  }, [monetaryBreakdownHeadCells]);

  const dataConvert = useCallback(
    (dataCells: Array<any>, headCells: IHeadCell[]) => {
      return dataCells?.map((row, index) => {
        const columns: any[] = [];

        forEach(headCells, (headCell) => {
          columns.push({
            id: headCell.id,
            value: headCell.transformFunction
              ? headCell.transformFunction(
                  row[headCell.id],
                  row,
                  order,
                  orderBy === headCell.sortBy || orderBy === headCell.id
                )
              : row[headCell.id],
          });
        });
        return mapValues(keyBy(columns, 'id'), 'value');
      });
    },
    [order, orderBy]
  );

  useEffect(() => {
    if (!['stop', 'fin'].includes(loadingExportDataStatus)) {
      setShowProgressInfo(true);
    }
    if (['fin'].includes(loadingExportDataStatus)) {
      setTimeout(() => setShowProgressInfo(false), 2000);
    }
  }, [loadingExportDataStatus]);

  const exportData = useCallback(
    (dataCells: Array<any>) => {
      const data = dataConvert(dataCells, headCells);
      const monetaryBreakdownData = dataConvert(dataCells, monetaryBreakdownHeadCells);

      const expandedMonetaryBreakdownData: any[] = [];
      for (const row of monetaryBreakdownData) {
        const proratedBreakdown = prorateMonetaryBreakdown({
          expectedAwardDate:
            row.expectedAwardDate && row.expectedAwardDate !== 'N/A'
              ? new Date(row.expectedAwardDate)
              : undefined,
          stage: row.stage,
          stageDate: row.stageDate
            ? new Date(row.stageDate)
            : new Date(row.lastStageChangedEvent?.createdAt || row.updatedAt),
          monetaryBreakdowns: row.monetaryBreakdowns,
          bidProposedValue: row.bidProposedValue,
          proposalCreatedAt: row.createdAt,
        }).sort((a, b) => a.year - b.year);

        if (!proratedBreakdown!.length) {
          expandedMonetaryBreakdownData.push(omit(row, ['monetaryBreakdowns']));
        } else {
          expandedMonetaryBreakdownData.push({
            ...row,
            mbYear: proratedBreakdown[0].year,
            monetaryBreakdowns: proratedBreakdown[0].value,
          });

          for (let i = 1; i < proratedBreakdown.length; i++) {
            expandedMonetaryBreakdownData.push({
              ...row,
              mbYear: proratedBreakdown[i].year,
              monetaryBreakdowns: proratedBreakdown[i].value,
            });
          }
        }
      }

      // TODO: Export To Chart Data

      setChartData([
        { sheetName: fileName, csvData: data, headings },
        {
          sheetName: 'Revenue Report',
          csvData: expandedMonetaryBreakdownData,
          headings: monetaryBreakdownHeadings,
          headerStyles: monetaryBreakdownHeadingStyles,
          rowStyles: monetaryBreakdownRowStyles,
        },
      ]);
      setShowCharting(true);
    },
    [
      headCells,
      monetaryBreakdownHeadCells,
      monetaryBreakdownHeadingStyles,
      monetaryBreakdownHeadings,
      monetaryBreakdownRowStyles,
      headings,
      dataConvert,
    ]
  );

  const exportPages = useCallback(async () => {
    const allPages = true;
    const maxRowsPerPage = 100;
    const result = await loadProposals({ allPages, rowsPerPage: maxRowsPerPage, variables });

    if (!result.error) {
      exportData(result.data);
    }
  }, [exportData, loadProposals, variables]);

  const exportProgress = useMemo(() => {
    return loadingExportDataStages.map(({ key, name }) => ({
      name,
      progress: Math.floor(
        (100 * loadingExportDataProgress[key].current) / loadingExportDataProgress[key].steps
      ),
    }));
  }, [loadingExportDataProgress, loadingExportDataStages]);

  const preconfigPage = useCallback(() => {
    setShowPreconfig(true);
  }, []);

  return (
    <>
      <ProgressInfo
        open={showProgressInfo}
        title={'Loading Data is in progress'}
        message={'Collecting data. Please wait...'}
        onClose={() => setShowProgressInfo(false)}
        progress={exportProgress}
      />
      <ChartingPreconfigDialog
        onClose={(config: any) => {
          setShowPreconfig(false);
          if (config) {
            setChartConfig(config);
            setShowCharting(() => true);
          }
        }}
        open={showPreconfig}
        data={chartData}
        onSubmit={(config) => {
          setShowPreconfig(false);
          if (config) {
            setChartConfig(config);
            exportPages();
          }
        }}
      />
      <ChartingDialog
        onClose={() => setShowCharting(false)}
        open={showCharting}
        data={chartData}
        config={chartConfig}
      />
      <Tooltip title="Charting Sandbox">
        <IconButton
          style={{ padding: '0' }}
          size="small"
          onClick={debounce(() => {
            preconfigPage();
          }, DEBOUNCE_TIMEOUT)}
        >
          <BarchartIcon fontSize="medium" style={{ color: '#006ad4' }} />
        </IconButton>
      </Tooltip>
    </>
  );
};
