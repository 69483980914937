import { ApolloError, useLazyQuery } from '@apollo/client';
import { useTableStorage } from 'components/ui/Table/storage/tableStorageHook';
import { IOrderByColumn } from 'components/ui/Table/Table';
import { DEFAULT_ROWS_PER_PAGE } from 'constants/config';
import { opportunitiesTableHead } from 'constants/opportunitiesTable';
import { GET_PROPOSALS, GET_PROPOSAL_COUNT } from 'graphql/proposals/proposals';
import { ProposalEntityType, ProposalFilter } from 'graphql/proposals/types/graphql-types';
import { proposalCount } from 'graphql/proposals/types/proposalCount';
import { proposals } from 'graphql/proposals/types/proposals';
import { pick } from 'lodash';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { withApolloErrorHandler } from 'utils/apolloErrorHandler';

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface IPageLoadParams {
  orderByColumns?: IOrderByColumn[] | undefined;
  page: number;
  rowsPerPage: number;
  filter?: any;
  filterValues?: any;
}

export interface IProposalsTableHookProps {
  initFilter?: Partial<ProposalFilter>;
  overridePageLoadParams?: any;
  tableStorageKey: string;
  initRowsPerPage?: number;
}

export interface IProposalsTableHookValue {
  totalItems: number;
  proposals: any;
  loadPage: (
    orderByColumns: IOrderByColumn[] | undefined,
    page: number,
    rowsPerPage: number
  ) => void;
  loading: boolean;
  error: ApolloError | undefined;
  onFilterChange: (filterValues: any) => boolean;
  clearAllFilters: () => void;
  filterApplied: any;
  pageLoadParams: any;
}

const defaultFilterValues = {
  owningDivision: { id: 'All', name: 'All' },
  stage: { id: 'All', name: 'All' },
  proposalType: { id: 'All', name: 'All' },
  successChance: { id: 'All', name: 'All' },
  workTypes: { id: 'All', name: 'All' },
  bidProposedValue: { id: 'All', name: 'All' },
  entityType: ProposalEntityType.OPPORTUNITY,
};

const useProposalsTableHookLocal = ({
  initFilter,
  overridePageLoadParams,
  tableStorageKey,
  initRowsPerPage,
}: IProposalsTableHookProps): IProposalsTableHookValue => {
  const { setItem, getItem } = useTableStorage({
    key: tableStorageKey,
  });

  const [totalItems, setTotalItems] = useState(0);
  const [proposals, setProposals] = useState<any[]>([]);
  const [pageLoadParams, setPageLoadParams] = useState<IPageLoadParams>({
    page: 0,
    rowsPerPage: initRowsPerPage || DEFAULT_ROWS_PER_PAGE,
    filter: initFilter,
    ...getItem(),
  });

  const [loadProposals, { called, data, loading, error, refetch }] = useLazyQuery<proposals>(
    GET_PROPOSALS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const [
    loadProposalsCount,
    {
      called: calledCount,
      data: dataCount,
      loading: loadingCount,
      error: errorCounter,
      refetch: refetchCount,
    },
  ] = useLazyQuery<proposalCount>(GET_PROPOSAL_COUNT, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (overridePageLoadParams) {
      setPageLoadParams((initial) => ({
        ...initial,
        ...overridePageLoadParams,
        filter: { ...initial.filter, ...overridePageLoadParams.filter },
      }));
    }
  }, [overridePageLoadParams]);

  useEffect(() => {
    const { rowsPerPage, page, orderByColumns, filter } = pageLoadParams;

    const variables = {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      sort: orderByColumns?.length
        ? orderByColumns.map(({ order, orderBy }) => ({ order, column: orderBy }))
        : undefined,
      filter: { ...filter },
    };

    if (called) {
      refetch!(variables);
    } else {
      loadProposals({ variables });
    }
  }, [loadProposals, refetch, called, pageLoadParams]);

  useEffect(() => {
    if (!data && !loading && error) {
      setProposals([]);
    }
  }, [data, loading, error]);

  useEffect(() => {
    const { filter } = pageLoadParams;

    const variables = {
      filter: { ...filter },
    };

    if (calledCount) {
      refetchCount!(variables);
    } else {
      loadProposalsCount({ variables });
    }
  }, [loadProposalsCount, refetchCount, calledCount, pageLoadParams]);

  useEffect(() => {
    if (!loadingCount && dataCount) {
      setTotalItems(dataCount.proposal_proposalCount);
    }
  }, [dataCount, loadingCount]);

  useEffect(() => {
    if (!loading && data) {
      setProposals(() => [...data.proposal_proposals]);
    }
  }, [data, loading]);

  useEffect(() => {
    setItem(pageLoadParams);
  }, [pageLoadParams, setItem]);

  const loadPage = useCallback(
    (orderByColumns: IOrderByColumn[] | undefined, page: number, rowsPerPage: number) => {
      setPageLoadParams((oldPageLoadParams) => ({
        ...oldPageLoadParams,
        orderByColumns,
        page,
        rowsPerPage,
      }));
    },
    []
  );

  const convertSelectedToIds = useCallback((item: { id: string } | undefined) => {
    return item && item.id !== 'All' ? [item.id] : undefined;
  }, []);

  const clearAllFilters = () => {
    onFilterChange(defaultFilterValues);
  };

  const onFilterChange = useCallback(
    (filterValues: any) => {
      if (pageLoadParams) {
        const bidProposedValueSelected = convertSelectedToIds(filterValues.bidProposedValue);
        const bidPair = bidProposedValueSelected
          ? bidProposedValueSelected[0].split('-')
          : [null, null];
        const newFilter = {
          nameContains: filterValues.name || undefined,
          stages: convertSelectedToIds(filterValues.stage),
          clientNameContains: filterValues.party || undefined,
          owningDivisionIds: convertSelectedToIds(filterValues.owningDivision),
          proposalTypes: convertSelectedToIds(filterValues.proposalType),
          proposalUserLeadNameContains: filterValues.leadUser || undefined,
          successChances: convertSelectedToIds(filterValues.successChance),
          dueDate: filterValues.dueDate,
          workTypeIds: convertSelectedToIds(filterValues.workTypes),
          bidProposedValueLow: bidPair[0] ? parseFloat(bidPair[0]) : undefined,
          bidProposedValueHigh:
            bidPair[1] && bidPair[1] !== '0' ? parseFloat(bidPair[1]) : undefined,
          entityType: filterValues.entityType || undefined,
          createdAt: filterValues.createdAt,
          updatedAt: filterValues.updatedAt,
        };

        Object.keys(newFilter).forEach((key) => {
          if (newFilter[key as keyof typeof newFilter] === undefined) {
            delete newFilter[key as keyof typeof newFilter];
          }
        });

        if (JSON.stringify(pageLoadParams.filter) !== JSON.stringify(newFilter)) {
          setPageLoadParams((oldPageLoadParams) => ({
            ...oldPageLoadParams,
            page: 0,
            filter: newFilter,
            filterValues,
          }));
          return true;
        }
      }
      return false;
    },
    [pageLoadParams, convertSelectedToIds]
  );

  const filterApplied = useMemo(() => {
    const { filterValues } = pageLoadParams;
    return (
      JSON.stringify(
        pick(
          filterValues,
          opportunitiesTableHead.filter((item) => !!item.filter).map((item) => item.id)
        )
      ) !==
      JSON.stringify(
        pick(
          defaultFilterValues,
          opportunitiesTableHead.filter((item) => !!item.filter).map((item) => item.id)
        )
      )
    );
  }, [pageLoadParams]);

  return {
    totalItems,
    proposals,
    loadPage,
    loading: loading || !!loadingCount,
    onFilterChange,
    filterApplied,
    pageLoadParams,
    error: error || errorCounter,
    clearAllFilters,
  };
};

export const useProposalsTableHook = withApolloErrorHandler(useProposalsTableHookLocal);
