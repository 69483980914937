import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Link,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

import { useGenericArrayToDropdown } from 'hooks/genericArrayToDropdownHook';
import { useSelectClient } from 'hooks/selectClientHook';
import { pick } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useComponentContext } from 'template/Proposal/ProposalContext';
import { Asterisks } from './components/Asterisks.tsx/Asterisks';
import { TwoColumnTemplate } from './components/ColumnsTemplate/TwoColumnTemplate';
import { CompetitorDetails } from './components/CompetitorDetails/CompetitorDetails';
import s from './style.module.scss';

import { useAdditionalDetailsFields } from './hooks/additionalDetailsFields';

import { EasyAutoComplete } from 'components';
import { ICountry, useCountriesAutoCompleteDataHook } from 'hooks/countriesAutocompleteDataHook';
import { useDivisions } from 'hooks/divisionsHook';
import { usePartyContacts } from 'hooks/partyContactsHook';
import { useUsersAutocompleteDataHook } from 'hooks/usersAutoCompleteDataHook';
import {
  chancesOfSuccessOptions,
  priceTypeOptions,
  yesNoOptions,
} from './constants/chancesOfSuccessOptions';
import { proposalTypeOptions } from './constants/proposalTypeOptions';
import { NewProposalWorkflowStates, proposalWorkflow, WorkflowStates } from './constants/workflow';

import {
  GET_BILLING_BASES,
  GET_BILLING_ORIGINS,
  GET_BUDGET_BASIS,
  GET_DELIVERABLES,
  GET_EVALUATION_TYPES,
  GET_GUIDELINE_CODES,
  GET_PROPOSAL_PROJECT_FIELD_COUNT,
  GET_PROPOSAL_PROJECT_FIELDS,
  GET_PROPOSAL_REPORT_TYPES,
  GET_PROPOSAL_SUBMISSION_METHODS,
  GET_REVENUE_AREAS,
  GET_WORK_TYPES,
} from 'graphql/proposals/options';
import { IField } from 'graphql/proposals/types/IField';
import {
  FieldSortableColumn,
  ProposalActivity,
  ProposalStage,
} from 'graphql/proposals/types/graphql-types';
import { useAutoCompleteDataSource } from 'hooks/autoCompleteDataSource';
import { useAutoCompleteSimpleDataSource } from 'hooks/autoCompleteSimpleDataSource';
import { ICompetitor } from 'template/Proposal/interfaces';
import { ClientProposals } from '../ClientProposals/ClientProposals';
import { ClientContactTagTooltip } from './components/ClientContactTagTooltip/ClientContactTagTooltip';
import { UserTagTooltip } from './components/UserTagTooltip/UserTagTooltip';

import { UserName } from 'components/UserName/UserName';
import { DEBOUNCE_TIMEOUT, MAX_OWNERS } from 'constants/config';
import { useConvertToProposalContext } from 'contexts/ConvertToProposalContext';
import { debounce } from 'lodash';
import { useComponentContext as useFormChangedDialogContext } from 'template/FormChangedDialog/FormChangedDialogContext';
import { Reviewers } from '../ProposalFileManagement/components/Reviewers/Reviewers';
import { IReviewer } from '../ProposalFileManagement/components/Reviewers/interfaces';
import { EditClientDialog } from './components/EditClientDialog/EditClientDialog';

import { paths } from 'constants/index';
import { useHistory } from 'react-router-dom';

import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
import LoadingOverlay from 'react-loading-overlay-ts';

const useStyles = makeStyles({
  content: {
    alignItems: 'baseline',
  },
  dropdownOpen: {
    transform: 'rotate(-180deg)',
  },
  dropdownClosed: {
    transform: 'rotate(0)',
  },
});

export const proposalQueries = {
  projectFields: {
    GET_QUERY: GET_PROPOSAL_PROJECT_FIELDS,
    GET_COUNT_QUERY: GET_PROPOSAL_PROJECT_FIELD_COUNT,
    getItemId: (item: any) => item.id,
    getItems: (data: any) =>
      data.fields.map((field: IField) => ({
        ...field,
        name: field.name + ', ' + field.code.substr(0, 3),
      })),
    getCount: (data: any) => data.fieldCount,
    search: '',
    filterToAPI: (search: string) => ({ codeOrNameContains: search }),
    order: [{ column: 'CODE', order: 'ASC' }],
  },
  billingOrigins: {
    GET_QUERY: GET_BILLING_ORIGINS,
    getItemId: (item: any) => item.id,
    getItems: (data: any) => [...data.billingOrigins].sort((a, b) => a.name.localeCompare(b.name)),
  },
  billingBases: {
    GET_QUERY: GET_BILLING_BASES,
    getItemId: (item: any) => item.id,
    getItems: (data: any) => [...data.billingBases].sort((a, b) => a.name.localeCompare(b.name)),
  },
  deliverables: {
    GET_QUERY: GET_DELIVERABLES,
    getItemId: (item: any) => item.id,
    getItems: (data: any) => [...data.deliverables].sort((a, b) => a.order - b.order),
  },
  evaluationTypes: {
    GET_QUERY: GET_EVALUATION_TYPES,
    getItemId: (item: any) => item.id,
    getItems: (data: any) => [...data.evaluationTypes].sort((a, b) => a.name.localeCompare(b.name)),
  },
  budgetBasis: {
    GET_QUERY: GET_BUDGET_BASIS,
    getItemId: (item: any) => item.id,
    getItems: (data: any) => [...data.budgetBasis].sort((a, b) => a.name.localeCompare(b.name)),
  },
  guidelineCodes: {
    GET_QUERY: GET_GUIDELINE_CODES,
    getItemId: (item: any) => item.id,
    getItems: (data: any) => [...data.guidelineCodes].sort((a, b) => a.name.localeCompare(b.name)),
  },
  revenueAreas: {
    GET_QUERY: GET_REVENUE_AREAS,
    getItemId: (item: any) => item.id,
    getItems: (data: any) => [...data.revenueAreas], //.sort((a, b) => a.name.localeCompare(b.name)),
  },
  workTypes: {
    GET_QUERY: GET_WORK_TYPES,
    getItemId: (item: any) => item.id,
    getItems: (data: any) => [...data.workTypes].sort((a, b) => a.name.localeCompare(b.name)),
  },
  proposalSubmissionMethods: {
    GET_QUERY: GET_PROPOSAL_SUBMISSION_METHODS,
    getItemId: (item: any) => item.id,
    getItems: (data: any) =>
      [...data.proposal_submissionMethods].sort((a, b) => a.name.localeCompare(b.name)),
  },
  proposalReportTypes: {
    GET_QUERY: GET_PROPOSAL_REPORT_TYPES,
    getItemId: (item: any) => item.id,
    getItems: (data: any) =>
      [...data.proposal_reportTypes].sort((a, b) => a.name.localeCompare(b.name)),
  },
};

const defaultExpanded = {
  BasicDetails: true,
  AdditionalDetails: true,
};

export interface IProposalFormProps {
  onReady?: () => void;
  printView?: boolean;
}

export const ProposalForm: FC<IProposalFormProps> = ({ onReady, printView }) => {
  const history = useHistory();
  const classes = useStyles();

  const {
    convertToProposal: { convert: convertToProposal },
    setConvertToProposal,
  } = useConvertToProposalContext();

  const { checkAndProceed } = useFormChangedDialogContext();

  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>(defaultExpanded);
  const [editClient, setEditClient] = useState<string>();

  const [showDisabledReviewers, setShowDisabledReviewers] = useState(false);

  const {
    proposal,
    proposalId,
    originalProposal,
    onChangeState,
    proposalStage,
    basicDetailsFields,
    usersListAsReviewers,
    ts,
    loading,
    stageDateCase,
    savedDateCase,
    currentDate,
  } = useComponentContext();
  const {
    id,
    selectedSuccessChance,
    selectedPriceType,
    selectedType,
    selectedClient,
    selectedClientContact,
    selectedDivisionOwnership,
    selectedDivisionsParticipating,
    selectedFiguresRequired,
    selectedTravelRequired,
    selectedStage,
    stageDate,
    stageReason,
    lostReason,
    showValidator,
    selectedCompetitors,
    selectedDivisionOpportunityOwner,
    selectedProjectCountries,
    selectedOwners,
    reviewers,
    createdAt,
    updatedAt,
    activity,
    lastStageChangedEvent,
  } = proposal;

  const { fields: additionalDetailsFields } = useAdditionalDetailsFields(proposal);

  const clientContacts = usePartyContacts({ loadPartyId: selectedClient?.key });
  const { divisionPairs } = useDivisions();

  const divisionPairs2 = useMemo(() => {
    return [...divisionPairs];
  }, [divisionPairs]);

  //// dohvati podatke sa paginacijom
  const clientContactProps = useGenericArrayToDropdown({
    selectedItem: selectedClientContact,
    options:
      clientContacts.contacts?.map((contact) => ({
        ...contact,
        name: (contact.firstName || '') + ' ' + (contact.lastName || ''),
      })) || [],
  });

  const clientContactTagTooltip = useCallback(
    (open: any, item: any) => {
      return (
        <ClientContactTagTooltip
          contact={item}
          partyId={selectedClient?.key}
        ></ClientContactTagTooltip>
      );
    },
    [selectedClient?.key]
  );

  const chancesOfSuccessProps = useGenericArrayToDropdown({
    selectedItem: selectedSuccessChance,
    options: chancesOfSuccessOptions,
  });

  const priceTypeProps = useGenericArrayToDropdown({
    selectedItem: selectedPriceType,
    options: priceTypeOptions,
  });

  const typeProps = useGenericArrayToDropdown({
    selectedItem: selectedType,
    options: proposalTypeOptions,
  });

  const clientProps = useSelectClient({
    error: false,
    selectedItem: selectedClient,
  });

  const clientActions: any = useMemo(() => {
    if (selectedClient) {
      return {
        edit: {
          onClick: () => {
            setEditClient(selectedClient.key);
          },
          tooltip: 'Edit a Client',
        },
      };
    }
    return undefined;
  }, [selectedClient]);

  const divisionOwnershipProps = useGenericArrayToDropdown({
    selectedItem: selectedDivisionOwnership,
    options: divisionPairs,
    // clientContacts.contacts?.map((contact) => ({ id: contact.id, name: contact.name })) || [],
  });

  const clientFocalPointProps = useUsersAutocompleteDataHook({
    search: '',
  });

  const leadProps = useUsersAutocompleteDataHook({
    search: '',
  });

  const submissionMethodsProps = useAutoCompleteSimpleDataSource<any, any, any>(
    proposalQueries.proposalSubmissionMethods
  );

  const divisionsParticipatingProps = useGenericArrayToDropdown({
    selectedItem: selectedDivisionsParticipating,
    options: divisionPairs2,
  });

  const selectedIndividualsInvolvedProps = useUsersAutocompleteDataHook({
    search: '',
  });

  const selectedIndividualsInvolvedTagTooltip = useCallback((open: any, item: any) => {
    return <UserTagTooltip user={item}></UserTagTooltip>;
  }, []);

  const selectedProjectCountriesProps = useCountriesAutoCompleteDataHook({
    search: '',
  });

  const divisionOpportunityOwnerProps = useGenericArrayToDropdown({
    selectedItem: selectedDivisionOpportunityOwner,
    options: divisionPairs2,
  });

  const selectedBillingOriginProps = useAutoCompleteSimpleDataSource<any, any, any>(
    proposalQueries.billingOrigins
  );

  const selectedProjectFieldsAutocompleteProps = useMemo(
    () => ({
      ...proposalQueries.projectFields,
      filterToAPI: (search: string) => ({
        nameContains: search,
        countryCodes: selectedProjectCountries?.map((country: ICountry) => country.code),
      }),
      order: [
        { column: FieldSortableColumn.COUNTRY_CODE, order: 'ASC' },
        { column: FieldSortableColumn.NAME, order: 'ASC' },
      ],
    }),
    [selectedProjectCountries]
  );

  const selectedProjectFieldsProps = useAutoCompleteDataSource<any, any, any, any>(
    selectedProjectFieldsAutocompleteProps
  );

  const selectedBillingBasisProps = useAutoCompleteSimpleDataSource<any, any, any>(
    proposalQueries.billingBases
  );

  const selectedWorkTypeProps = useAutoCompleteSimpleDataSource<any, any, any>(
    proposalQueries.workTypes
  );

  const selectedEvaluationTypeProps = useAutoCompleteSimpleDataSource<any, any, any>(
    proposalQueries.evaluationTypes
  );
  const selectedBudgetBasisProps = useAutoCompleteSimpleDataSource<any, any, any>(
    proposalQueries.budgetBasis
  );
  const selectedGuidelineCodeProps = useAutoCompleteSimpleDataSource<any, any, any>(
    proposalQueries.guidelineCodes
  );
  const potentialRevenueByAreaProps = useAutoCompleteSimpleDataSource<any, any, any>(
    proposalQueries.revenueAreas
  );
  const selectedDeliverablesProps = useAutoCompleteSimpleDataSource<any, any, any>(
    proposalQueries.deliverables
  );

  const reportTypeProps = useAutoCompleteSimpleDataSource<any, any, any>(
    proposalQueries.proposalReportTypes
  );

  const figuresRequiredProps = useGenericArrayToDropdown({
    selectedItem: selectedFiguresRequired,
    options: yesNoOptions,
  });

  const travelRequiredProps = useGenericArrayToDropdown({
    selectedItem: selectedTravelRequired,
    options: yesNoOptions,
  });

  const potentialTeamMembersProps = useUsersAutocompleteDataHook({
    search: '',
  });

  const proposalOwnersProps = useUsersAutocompleteDataHook({
    search: '',
  });

  const basicDetailsFieldsToShow = useMemo(() => {
    if (!expanded['BasicDetails']) {
      const lists = basicDetailsFields?.map((row) => row.filter((item) => item.showColapsed)) || [];
      return lists.filter((item) => item.length);
    }
    return basicDetailsFields;
  }, [expanded, basicDetailsFields]);

  const additionalDetailsFieldsToShow = useMemo(() => {
    if (!expanded['AdditionalDetails']) {
      const lists = additionalDetailsFields.map((row) => row.filter((item) => item.showColapsed));
      return lists.filter((item) => item.length);
    }
    return additionalDetailsFields;
  }, [expanded, additionalDetailsFields]);

  const basicDetailsMeta = {
    selectedType: {
      props: {
        ...typeProps,
      },
    },
    selectedSuccessChance: {
      props: {
        ...chancesOfSuccessProps,
      },
    },
    selectedClient: {
      props: {
        ...clientProps,
      },
      actions: clientActions,
    },
    selectedClientContact: {
      props: {
        ...clientContactProps,
        tagTooltip: clientContactTagTooltip,
        getOptionSelected: (option: any, value: any) => {
          return option.id === value.id;
        },
      },
    },
    selectedDivisionOwnership: {
      props: {
        ...divisionOwnershipProps,
      },
    },
    selectedClientFocalPoint: {
      props: {
        ...clientFocalPointProps,
      },
    },
    selectedLead: {
      props: {
        ...leadProps,
      },
    },
    selectedSubmissionMethods: {
      props: {
        ...submissionMethodsProps,
      },
    },
    selectedDivisionsParticipating: {
      props: {
        ...divisionsParticipatingProps,
      },
    },
    selectedIndividualsInvolved: {
      props: {
        ...selectedIndividualsInvolvedProps,
        tagTooltip: selectedIndividualsInvolvedTagTooltip,
      },
    },

    selectedDivisionOpportunityOwner: {
      props: {
        ...divisionOpportunityOwnerProps,
      },
    },
    scopeOfWorkDescription: {
      props: {
        minRows: 3,
      },
    },
  };

  const advancedDetailsMeta = {
    selectedPriceType: {
      props: {
        ...priceTypeProps,
      },
    },
    scopeOfWork: {
      props: {
        minRows: 3,
      },
    },
    proposalRequestReceivedDate: {
      props: {},
    },
    proposalDueDate: {
      props: {},
    },

    businessDecisionsToConsider: {
      props: {
        minRows: 3,
      },
    },
    expectedAwardDate: {
      props: {},
    },
    expectedProjectStartDate: {
      props: {},
    },
    expectedProjectEndDate: {
      props: {},
    },
    selectedProjectCountries: {
      props: { ...selectedProjectCountriesProps },
    },

    selectedBillingOrigin: {
      props: { ...selectedBillingOriginProps },
    },
    selectedProjectFields: {
      props: { ...selectedProjectFieldsProps },
    },
    selectedBillingBasis: {
      props: { ...selectedBillingBasisProps },
    },
    selectedWorkType: {
      props: { ...selectedWorkTypeProps },
    },
    selectedEvaluationType: {
      props: { ...selectedEvaluationTypeProps },
    },
    selectedBudgetBasis: {
      props: { ...selectedBudgetBasisProps },
    },
    selectedGuidelineCode: {
      props: { ...selectedGuidelineCodeProps },
    },
    selectedDeliverables: {
      props: { ...selectedDeliverablesProps },
    },
    selectedPotentialRevenueByArea: {
      props: { ...potentialRevenueByAreaProps },
    },
    selectedReportType: {
      props: { ...reportTypeProps },
    },
    selectedFiguresRequired: {
      props: { ...figuresRequiredProps },
    },
    selectedTravelRequired: {
      props: { ...travelRequiredProps },
    },
    selectedPotentialTeamMembers: {
      props: { ...potentialTeamMembersProps },
    },
  };

  const onAddNewCompetitor = useCallback(() => {
    onChangeState!((proposal) => ({
      ...proposal,
      selectedCompetitors: [
        ...selectedCompetitors,
        {
          changed: false,
          deleted: false,
          hasWonBid: false,
        },
      ],
    }));
  }, [onChangeState, selectedCompetitors]);

  const onDeleteCompetitor = useCallback(
    (index: number) => () => {
      onChangeState!((proposal) => {
        const competitors = [...proposal.selectedCompetitors];
        competitors.splice(index, 1);
        return { ...proposal, selectedCompetitors: competitors };
      });
    },
    [onChangeState]
  );

  const onSetCompetitor = useCallback(
    (index: number) => (value: Partial<ICompetitor>) => {
      onChangeState!((proposal) => {
        proposal.selectedCompetitors[index] = {
          ...proposal.selectedCompetitors[index],
          ...value,
          changed: true,
        };
        return { ...proposal };
      });
    },
    [onChangeState]
  );

  const stakeholderListChanged = useCallback(
    (items: IReviewer[]): void => {
      onChangeState!((proposal) => {
        return {
          ...proposal,
          reviewers: [...items],
        };
      });
    },
    [onChangeState]
  );

  const isNewItem = useMemo(() => {
    if (!proposalId) {
      return true;
    }
    const id = parseInt(proposalId);
    return id <= 0;
  }, [proposalId]);

  const onFieldChange = useCallback(
    (id: any) => (value: any) => {
      onChangeState!((old) => {
        return { ...old, [id]: value };
      });
    },
    [onChangeState]
  );

  const onFieldUndo = useCallback(
    (id: any) => () => {
      onChangeState!((old) => {
        return { ...old, [id]: originalProposal[id as keyof typeof originalProposal] };
      });
    },
    [onChangeState, originalProposal]
  );

  const hasFieldChanged = useCallback(
    (id: any) => {
      return (
        JSON.stringify(proposal[id as keyof typeof proposal]) !==
        JSON.stringify(originalProposal[id as keyof typeof originalProposal])
      );
    },
    [proposal, originalProposal]
  );

  const onStatusFieldChange = useCallback(
    (value: any) => {
      onChangeState!((old) => {
        return { ...old, selectedStage: value, showValidator: false };
      });
    },
    [onChangeState]
  );

  const onSelectedOwnersFieldChange = useCallback(
    (value: any) => {
      onChangeState!((old) => {
        return { ...old, selectedOwners: value, showValidator: false };
      });
    },
    [onChangeState]
  );

  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.up('md'));

  const proposalStagePairs = useMemo(() => {
    if (proposalId) {
      return WorkflowStates.map((item) => ({
        id: item.id as string,
        name: item.name,
      }));
    } else {
      return NewProposalWorkflowStates.map((item) => ({
        id: item.id as string,
        name: item.name,
      }));
    }
  }, [proposalId]);

  const proposalStageProps = useGenericArrayToDropdown({
    selectedItem: selectedStage,
    options: proposalStagePairs,
    sort: (a, b) => a.name.localeCompare(b.name),
  });

  const onSelectedOwnersChange = useCallback(
    (value: any) => {
      onSelectedOwnersFieldChange!(value);
    },
    [onSelectedOwnersFieldChange]
  );

  const onStageReasonChange = useCallback(
    (e: React.ChangeEvent<{ value: string }>) => {
      onFieldChange!('stageReason')(e.target.value);
    },
    [onFieldChange]
  );

  const onLostReasonChange = useCallback(
    (e: React.ChangeEvent<{ value: string }>) => {
      onFieldChange!('lostReason')(e.target.value);
    },
    [onFieldChange]
  );

  const onStageDateChange = useCallback(
    (date: any | null) => {
      onFieldChange!('stageDate')(date);
    },
    [onFieldChange]
  );

  const onProposalStageChange = useCallback(
    (value: any) => {
      if (value.id === originalProposal.stageAsString) {
        onStageDateChange(originalProposal.stageDate);
      } else {
        onStageDateChange(null);
      }
      onStatusFieldChange!(value);
    },
    [onStatusFieldChange, onStageDateChange, originalProposal]
  );

  const workflowTransition = useMemo(() => {
    return proposalWorkflow
      .find((item) => item.state === proposalStage)
      ?.to.find((item) => item.toState === selectedStage?.id);
  }, [proposalStage, selectedStage?.id]);

  const { askForReason = undefined, askForLoss = undefined } = workflowTransition || {};

  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    window.scrollTo({ top: 0 });
  }, [proposal.id, ts]);

  const [relatedProposalsLoaded, setRelatedProposalsLoaded] = useState(isNewItem);

  const setRelatedProposalsLoading = useCallback(
    ({ loading, data, error }: { loading: boolean; data: any; error: boolean }) => {
      setRelatedProposalsLoaded(!loading && (!!data || !!error));
    },
    []
  );

  useEffect(() => {
    if (!!onReady) {
      const {
        loading: leadPropsLoading,
        items: leadPropsItems,
        error: leadPropsError,
      } = leadProps || {};
      const leadPropsLoaded = !leadPropsLoading && (!!leadPropsItems || !!leadPropsError);

      if (relatedProposalsLoaded && leadPropsLoaded) {
        setTimeout(onReady, 0);
      }
    }
  }, [onReady, relatedProposalsLoaded, leadProps]);

  const ArrowIcon = (): JSX.Element => {
    if (!showDisabledReviewers) {
      return (
        <ArrowDropDownIcon
          fontSize="large"
          style={{ marginLeft: '16px', verticalAlign: 'middle' }}
        />
      );
    }
    return (
      <ArrowDropUpIcon fontSize="large" style={{ marginLeft: '16px', verticalAlign: 'middle' }} />
    );
  };

  const toggleVisible = (e: any): void => {
    e.preventDefault();
    e.cancelBubble = true;
    e.stopPropagation();
    setShowDisabledReviewers((old: boolean) => !old);
  };

  const getHideShowTitle = (): string =>
    showDisabledReviewers ? 'hide disabled' : 'show disabled';

  const existsDisabledReviewer = useMemo(() => {
    return !!reviewers.find((reviewer) => !!reviewer.disabled);
  }, [reviewers]);

  // const calculatedStageDate = useMemo(() => {
  //   if (selectedStage?.id === proposalStage) {
  //     if (stageDate) {
  //       return stageDate;
  //     }
  //     return lastStageChangedEvent?.createdAt ?? updatedAt;
  //   }
  //   return new Date();
  // }, [lastStageChangedEvent, proposalStage, stageDate, updatedAt, selectedStage]);

  return (
    <div className={s.dataBlockContainer}>
      {editClient ? (
        <EditClientDialog
          id={editClient}
          onClose={() => {
            setEditClient(undefined);
            onChangeState &&
              onChangeState!((old) => {
                return { ...old, selectedClient: { ...old.selectedClient! } };
              });
          }}
        ></EditClientDialog>
      ) : undefined}

      <LoadingOverlay
        active={
          loading ||
          selectedProjectFieldsProps.loading ||
          selectedBillingBasisProps.loading ||
          selectedWorkTypeProps.loading ||
          selectedEvaluationTypeProps.loading ||
          selectedBudgetBasisProps.loading ||
          selectedGuidelineCodeProps.loading ||
          potentialRevenueByAreaProps.loading ||
          selectedDeliverablesProps.loading ||
          reportTypeProps.loading ||
          potentialTeamMembersProps.loading ||
          proposalOwnersProps.loading ||
          clientContacts.loading ||
          clientProps.loading ||
          clientFocalPointProps.loading ||
          leadProps.loading ||
          submissionMethodsProps.loading ||
          selectedIndividualsInvolvedProps.loading ||
          selectedProjectCountriesProps.loading ||
          selectedBillingOriginProps.loading
        }
        spinner
        text="Please wait..."
      >
        <Grid container padding={0} spacing={3} className={s.dataBlockContainer}>
          {setConvertToProposal && proposal.id ? (
            <Grid xs={12} className={s.dataBlock}>
              <Grid container padding={0} spacing={1}>
                <Grid xs={12} md={3} className={s.label_grid} style={{}}>
                  <Typography variant="body2" className="label-title-nocase">
                    Convert to Proposal:
                  </Typography>
                </Grid>
                <Grid xs={12} md={5}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={convertToProposal}
                        onChange={() => {
                          if (convertToProposal) {
                            checkAndProceed!(() => {
                              setConvertToProposal({
                                convert: false,
                              });
                              history.push(
                                paths.client.OPPORTUNITY_DETAILS.replace(':id', proposal.id)
                              );
                            })();
                          }
                        }}
                      ></Switch>
                    }
                    label={convertToProposal ? 'Convert' : 'No'}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : undefined}

          <Grid xs={12} className={s.dataBlock}>
            <Grid container padding={0} spacing={1}>
              <Grid xs={12} md={3} className={s.label_grid}>
                <Typography variant="body2" className="label-title-nocase">
                  Proposal Stage:
                </Typography>
              </Grid>
              <Grid xs={12} md={5}>
                <EasyAutoComplete
                  key={`eac-${JSON.stringify(selectedStage)}`}
                  label=""
                  textFieldStyle="outlined"
                  optionsLabel="name"
                  selected={selectedStage}
                  selectedChanged={onProposalStageChange}
                  {...proposalStageProps}
                  getOptionSelected={(option: any, value: any) => {
                    return option.id === value.id;
                  }}
                />
              </Grid>

              {activity === ProposalActivity.STALE ? (
                <Grid
                  xs={12}
                  md={4}
                  style={{
                    textAlign: 'end',
                    color: 'red',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    //  fontStyle: 'italic'
                  }}
                >
                  STALE
                </Grid>
              ) : undefined}
            </Grid>
          </Grid>
          {askForReason ? (
            <Grid xs={12} className={s.dataBlock}>
              <Grid container padding={0} spacing={1}>
                <Grid xs={12} md={3}>
                  <Typography variant="body2" className="label-title-nocase">
                    Status Reason<Asterisks count={1}></Asterisks>:
                  </Typography>
                </Grid>
                <Grid xs={12} md={5}>
                  <TextareaAutosize
                    // className="event-logs-comment-input"
                    className="MuiInputBase-input"
                    color="grey"
                    style={{
                      width: '100%',
                      minHeight: '1rem',
                      padding: 10,
                      borderRadius: 4,
                      border: showValidator && !stageReason ? '1px red solid' : '1px #ccc solid',
                      overflow: 'auto',
                    }}
                    aria-label="minimum height"
                    minRows={4}
                    value={stageReason || ''}
                    placeholder="Input status description…"
                    onChange={onStageReasonChange}
                  ></TextareaAutosize>
                </Grid>
              </Grid>
            </Grid>
          ) : undefined}
          {askForLoss ? (
            <Grid xs={12} className={s.dataBlock}>
              <Grid container padding={0} spacing={1}>
                <Grid xs={12} md={3}>
                  <Typography variant="body2" className="label-title-nocase">
                    Lost Reason<Asterisks count={1}></Asterisks>:
                  </Typography>
                </Grid>
                <Grid xs={12} md={5}>
                  <TextareaAutosize
                    // className="event-logs-comment-input"
                    className="MuiInputBase-input"
                    color="grey"
                    style={{
                      width: '100%',
                      minHeight: '1rem',
                      padding: 10,
                      borderRadius: 4,
                      border:
                        showValidator && !lostReason
                          ? 'solid 1px rgba(255, 0, 0, .3)'
                          : '1px #ccc solid',
                      overflow: 'auto',
                    }}
                    aria-label="minimum height"
                    minRows={4}
                    value={lostReason || ''}
                    placeholder="Input status description…"
                    onChange={onLostReasonChange}
                  ></TextareaAutosize>
                </Grid>
              </Grid>
            </Grid>
          ) : undefined}
          {selectedStage?.id &&
          [ProposalStage.WON, ProposalStage.CANCELED, ProposalStage.LOST].includes(
            selectedStage?.id
          ) ? (
            <Grid xs={12} className={s.dataBlock}>
              <Grid container padding={0} spacing={1}>
                <Grid xs={12} md={3}>
                  <Typography variant="body2" className="label-title-nocase">
                    Stage Date:
                  </Typography>
                </Grid>
                <Grid xs={12} md={5}>
                  <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
                    <div>
                      <DatePicker
                        value={
                          stageDate ??
                          (selectedStage.id === originalProposal.stageAsString
                            ? lastStageChangedEvent?.createdAt
                            : currentDate)
                        }
                        onChange={onStageDateChange}
                        componentsProps={{
                          actionBar: {
                            actions: ['clear', 'today', 'cancel'] as PickersActionBarAction[],
                          },
                        }}
                        renderInput={(props) => (
                          <TextField
                            variant="outlined"
                            style={{
                              backgroundColor: 'white',
                            }}
                            size="small"
                            {...props}
                            // error
                          />
                        )}
                        // disabled
                      />
                    </div>
                    <div>
                      {stageDateCase}
                      {savedDateCase !== stageDateCase ? ' (changed)' : undefined}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          ) : undefined}
        </Grid>

        <div style={{ marginTop: '12px' }} />
        <Grid container padding={0} spacing={3} className={s.dataBlockContainer}>
          <Grid xs={12} className={s.dataBlock}>
            <Grid container padding={0} spacing={1}>
              <Grid xs={12} md={3}>
                <Typography variant="body2" className="label-title-nocase">
                  Owners:
                </Typography>
              </Grid>
              <Grid xs={12} md={5}>
                {id?.length ? (
                  <EasyAutoComplete
                    key={`eac-owners`}
                    label=""
                    textFieldStyle="outlined"
                    optionsLabel="name"
                    selected={selectedOwners}
                    selectedChanged={onSelectedOwnersChange}
                    multiple={true}
                    {...proposalOwnersProps}
                    getOptionSelected={(option: any, value: any) => {
                      return option.id === value.id;
                    }}
                    error={
                      showValidator &&
                      (!selectedOwners ||
                        !selectedOwners.length ||
                        selectedOwners.length > MAX_OWNERS)
                    }
                  />
                ) : (
                  <UserName></UserName>
                )}
              </Grid>
            </Grid>
          </Grid>
          {!isNewItem ? (
            <Grid xs={12} className={s.dataBlock}>
              <Grid container padding={0} spacing={1}>
                <Grid xs={12} md={3}>
                  <Typography variant="body2" className="label-title-nocase">
                    Created Date:
                  </Typography>
                </Grid>
                <Grid xs={12} md={5}>
                  {createdAt
                    ? new Date(createdAt).toLocaleDateString('en-EN', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })
                    : '--'}
                </Grid>
              </Grid>
              <Box m={3}></Box>
              <Grid container padding={0} spacing={1}>
                <Grid xs={12} md={3}>
                  <Typography variant="body2" className="label-title-nocase">
                    Last Modified Date:
                  </Typography>
                </Grid>
                <Grid xs={12} md={5}>
                  {createdAt
                    ? new Date(updatedAt).toLocaleDateString('en-EN', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })
                    : '--'}
                </Grid>
              </Grid>
            </Grid>
          ) : undefined}
        </Grid>

        <Box m={3} />
        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12} style={{ display: 'flex' }}>
            <Grid xs={12} style={{ padding: '0 15%' }}>
              <Divider />
              <Box m={1} />
            </Grid>
          </Grid>
        </Grid>

        <Box m={3} />
        <Grid container padding={0} spacing={4} className={s.dataBlock}>
          <Grid xs={12}>
            <div
              style={{ width: '100%', display: 'flex', cursor: 'pointer' }}
              onClick={debounce(() => {
                setExpanded((old) => {
                  return { ...old, BasicDetails: !old['BasicDetails'] };
                });
              }, DEBOUNCE_TIMEOUT)}
            >
              <div style={{ width: '100%' }}>
                <Typography variant="body2" className="label-section">
                  Basic Details:
                </Typography>
              </div>
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }} className={s.expandSection}>
                <div style={{ whiteSpace: 'nowrap', margin: 'auto 5px' }} className={s.expandText}>
                  {expanded['BasicDetails'] ? 'Show Less' : 'Show More'}
                </div>
                <div>
                  <ExpandMoreIcon
                    style={{ color: '#006ad4' }}
                    className={
                      expanded['BasicDetails'] ? classes.dropdownOpen : classes.dropdownClosed
                    }
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <TwoColumnTemplate
          data={
            basicDetailsFieldsToShow?.map((list) => {
              return list.map((item) =>
                pick(item, ['title', 'field', 'id', 'group', 'parse', 'hidden'])
              );
            }) || []
          }
          meta={basicDetailsMeta}
          values={proposal}
          onFieldChange={onFieldChange}
          onFieldUndo={onFieldUndo}
          hasFieldChanged={hasFieldChanged}
          printView={printView}
        ></TwoColumnTemplate>

        {expanded['BasicDetails'] ? (
          <>
            <Grid container padding={0} spacing={2}>
              <Grid xs={12}>
                <Grid xs={12}>
                  {/* <Divider /> */}
                  <Box m={2} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container padding={0} spacing={2} className={s.dataBlockContainer}>
              <Grid xs={12} className={s.dataBlock}>
                <Typography variant="body2" className="label-title-nocase">
                  Previously Submitted Proposals and Opportunities for{' '}
                  {selectedClient?.name ? selectedClient.name : 'Client'}:
                </Typography>
              </Grid>
              <Grid xs={12} className={s.dataBlock}>
                {selectedClient?.key ? (
                  <ClientProposals
                    key={selectedClient?.key}
                    clientId={selectedClient?.key}
                    editingProposalId={id}
                    excludeProposalId={id} // Add to enable filterout current proposal
                    setLoading={setRelatedProposalsLoading}
                    printView={printView}
                  ></ClientProposals>
                ) : (
                  <>
                    <span style={{ opacity: '0.6' }}>
                      Select a Client to view previously submitted proposals
                    </span>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        ) : undefined}

        <Box m={3} />
        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12} style={{ display: 'flex' }}>
            <Grid xs={12} style={{ padding: '0 15%' }}>
              <Divider />
              <Box m={1} />
            </Grid>
          </Grid>
        </Grid>

        <Box m={3} />

        <Grid container padding={0} spacing={4} className={s.dataBlock}>
          <Grid xs={12}>
            <div
              style={{ width: '100%', display: 'flex', cursor: 'pointer' }}
              onClick={debounce(() => {
                setExpanded((old) => {
                  return { ...old, AdditionalDetails: !old['AdditionalDetails'] };
                });
              }, DEBOUNCE_TIMEOUT)}
            >
              <div style={{ width: '100%' }}>
                <Typography variant="body2" className="label-section">
                  Additional Details:
                </Typography>
              </div>
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }} className={s.expandSection}>
                <div style={{ whiteSpace: 'nowrap', margin: 'auto 5px' }} className={s.expandText}>
                  {expanded['AdditionalDetails'] ? 'Show Less' : 'Show More'}
                </div>
                <div>
                  <ExpandMoreIcon
                    style={{ color: '#006ad4' }}
                    className={
                      expanded['AdditionalDetails'] ? classes.dropdownOpen : classes.dropdownClosed
                    }
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <TwoColumnTemplate
          data={additionalDetailsFieldsToShow.map((list) => {
            return list.map((item) =>
              pick(item, ['title', 'field', 'id', 'group', 'parse', 'hidden'])
            );
          })}
          meta={advancedDetailsMeta}
          values={proposal}
          onFieldChange={onFieldChange}
          onFieldUndo={onFieldUndo}
          hasFieldChanged={hasFieldChanged}
          printView={printView}
        ></TwoColumnTemplate>

        <Box m={3}></Box>

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12} style={{ display: 'flex' }}>
            <Grid xs={12} style={{ padding: '0 15%' }}>
              <Divider />
              <Box m={1} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12}>
            <Grid container padding={0} spacing={2} alignContent="center" alignItems="center">
              <>
                <Grid xs={5} lg={3}>
                  <Typography variant="body2" className="label-title">
                    Reviewers:
                  </Typography>
                </Grid>
                <Grid xs={7} lg={6} style={{ textAlign: 'right' }}>
                  {existsDisabledReviewer ? (
                    <Link component="button" onClick={toggleVisible}>
                      <Typography variant="caption">{getHideShowTitle()}</Typography>
                      <ArrowIcon />
                    </Link>
                  ) : undefined}
                </Grid>

                <Grid xs={12} style={{ maxWidth: '800px' }}>
                  <Reviewers
                    key={updatedAt ? JSON.stringify(updatedAt) : 'new'}
                    readOnly={false}
                    editMode
                    onChange={stakeholderListChanged}
                    reviewers={reviewers}
                    users={usersListAsReviewers}
                    error={showValidator && (!reviewers || !reviewers.length)}
                    hideDisabled={!showDisabledReviewers}
                  ></Reviewers>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Grid>

        <Box m={3}></Box>

        <Grid container padding={0} spacing={2} className={s.dataBlock}>
          <Grid xs={12} style={{ display: 'flex' }}>
            <Grid xs={12} style={{ padding: '0 15%' }}>
              <Divider />
              <Box m={1} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container padding={0} spacing={4}>
          <Grid xs={12}>
            <div style={{ width: '100%', display: 'flex' }}>
              <div style={{ width: '100%' }}>
                <Typography variant="body2" className="label-section">
                  Competitor Details:
                </Typography>
              </div>
              <div
                style={{ whiteSpace: 'nowrap', margin: 'auto 5px' }}
                className="expand-section"
              ></div>
              <div></div>
            </div>
          </Grid>
        </Grid>

        <Box m={1} />

        <CompetitorDetails
          competitors={selectedCompetitors}
          onDelete={onDeleteCompetitor}
          setValue={onSetCompetitor}
        ></CompetitorDetails>

        <Box m={2} />

        <Grid container padding={0} spacing={4}>
          <Grid xs={5}>
            <Typography variant="body2" className="label-title">
              <Button
                variant="outlined"
                color="primary"
                onClick={debounce(onAddNewCompetitor, DEBOUNCE_TIMEOUT)}
                style={{ whiteSpace: 'nowrap' }}
                size="small"
              >
                ADD COMPETITOR
              </Button>
            </Typography>
          </Grid>
          <Grid xs={7}></Grid>
        </Grid>

        <Box m={4}></Box>

        <Grid container padding={0} spacing={1}>
          <Grid xs={12}>
            <Asterisks count={1}></Asterisks> - Required input for creating Draft Proposal
          </Grid>
          <Grid xs={12}>
            <Asterisks count={2}></Asterisks> - Required input for non Draft Proposal
          </Grid>
        </Grid>

        <Box m={4}></Box>
      </LoadingOverlay>
    </div>
  );
};
