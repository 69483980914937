import { gql } from '@apollo/client';
import { ICountry } from './countriesFragments';
import { IDivisionName, IParty } from './partiesFragments';
import { IPartyContact } from './partyContactFragments';
import { IField } from './projectsFragments';
import { IBondFile } from './fileManagerFragments';

export const IBondListItem = gql`
  fragment IBondListItem on Bond {
    id
    type
    amount
    currency
    tenderNumber
    tenderName
    contractNumber
    contractName
    validityThroughDate
    recipientReceiveDeadline
    stage
    isLocked
    proposal {
      id
      name
      stage
    }
    party {
      id
      name
      isActive
      projectSetupClientCode
      partyType {
        name
      }
    }
    owningDivision {
      id
      name
    }
  }
`;

export const IBondInfo = gql`
  fragment IBondInfo on Bond {
    id
    type
    amount
    currency
    tenderNumber
    tenderName
    contractNumber
    contractName
    validityThroughDate
    recipientReceiveDeadline
    stage
    isLocked
  }
`;

export const IBondEventPayload = gql`
  fragment IBondEventPayload on BondEventPayload {
    partyId
    proposalId
    type
    tenderNumber
    tenderName
    contractNumber
    contractName
    description
    beneficiaryNameAndAddress
    beneficiaryPhoneNumber
    beneficiaryFaxNumber
    beneficiaryEmail
    recipientReceiveDeadline
    validityThroughDate
    amount
    currency
    issuingBankRequirements
    wordingOrFormatRequirements
    deliveryInstructions
    stage

    previousPartyId
    previousProposalId
    previousType
    previousTenderNumber
    previousTenderName
    previousContractNumber
    previousContractName
    previousDescription
    previousBeneficiaryNameAndAddress
    previousBeneficiaryPhoneNumber
    previousBeneficiaryFaxNumber
    previousBeneficiaryEmail
    previousRecipientReceiveDeadline
    previousValidityThroughDate
    previousAmount
    previousCurrency
    previousIssuingBankRequirements
    previousWordingOrFormatRequirements
    previousDeliveryInstructions

    bondFileType
    originalFilename
    blobContainer
    blobName
    mimeType
  }
`;

export const IBondEvent = gql`
  fragment IBondEvent on BondEvent {
    eventType
    createdAt
    author {
      id
      name
      email
    }
  }
`;

export const IBondEventWithPayload = gql`
  fragment IBondEventWithPayload on BondEvent {
    ...IBondEvent
    payload {
      ...IBondEventPayload
    }
  }
  ${IBondEvent}
  ${IBondEventPayload}
`;

export const IBond = gql`
  fragment IBond on Bond {
    ...IBondInfo

    description
    beneficiaryNameAndAddress
    beneficiaryPhoneNumber
    beneficiaryFaxNumber
    beneficiaryEmail
    issuingBankRequirements
    wordingOrFormatRequirements
    deliveryInstructions
    createdAt
    updatedAt

    files {
      ...IBondFile
    }

    proposal {
      id
      name
      stage
    }

    party {
      id
      name
      isActive
      projectSetupClientCode
      partyType {
        name
      }
    }
    owningDivision {
      id
      name
    }
    events {
      ...IBondEventWithPayload
    }
  }
  ${IBondInfo}
  ${IBondFile}
  ${IBondEventWithPayload}
`;

export const ICompetitor = gql`
  fragment ICompetitor on Competitor {
    id
    name
  }
`;

export const IProposalCompetitor = gql`
  fragment IProposalCompetitor on ProposalCompetitor {
    hasWonBid
    bidValue
    notes
    createdAt
    updatedAt
    competitor {
      ...ICompetitor
    }
  }
  ${ICompetitor}
`;

export const IProposalEvent = gql`
  fragment IProposalEvent on ProposalEvent {
    eventType
    payload
    createdAt
    author {
      persona
      user {
        id
        name
        email
      }
    }
    subject {
      user {
        name
      }
    }
    file {
      originalFilename
    }
  }
`;

export const IBillingBasis = gql`
  fragment IBillingBasis on BillingBasis {
    id
    name
  }
`;

export const IBillingOrigin = gql`
  fragment IBillingOrigin on BillingOrigin {
    id
    name
  }
`;

export const IDeliverable = gql`
  fragment IDeliverable on Deliverable {
    id
    order
    name
    projectSetupCode
    type
  }
`;

export const IProposalReportType = gql`
  fragment IProposalReportType on ProposalReportType {
    id
    name
  }
`;

export const IRevenueArea = gql`
  fragment IRevenueArea on RevenueArea {
    id
    name
    order
  }
`;

export const ISubmissionMethod = gql`
  fragment ISubmissionMethod on SubmissionMethod {
    id
    name
  }
`;

export const IWorkType = gql`
  fragment IWorkType on WorkType {
    id
    projectSetupCode
    name
  }
`;

export const IEvaluationType = gql`
  fragment IEvaluationType on EvaluationType {
    id
    projectSetupCode
    name
  }
`;

export const IBudgetBasis = gql`
  fragment IBudgetBasis on BudgetBasis {
    id
    name
  }
`;

export const IGuidelineCode = gql`
  fragment IGuidelineCode on GuidelineCode {
    id
    projectSetupCode
    name
  }
`;

export const IProposalDocumentUser = gql`
  fragment IProposalDocumentUser on ProposalDocumentUser {
    proposalDocumentId
    reviewStatus
    user {
      id
      name
      email
    }
    isEnabled
  }
`;

export const IProposalUser = gql`
  fragment IProposalUser on ProposalUser {
    order
    persona
    reviewStatus
    user {
      id
      oid
      email
      name
      discipline
    }
    isEnabled
    reviewerAssignedDocuments {
      ...IProposalDocumentUser
    }
  }
  ${IProposalDocumentUser}
`;

export const IProposalUserRef = gql`
  fragment IProposalUserRef on ProposalUser {
    user {
      id
      oid
      email
      name
    }
    isEnabled
  }
`;

export const IProposalDivisionName = gql`
  fragment IProposalDivisionName on ProposalDivision {
    division {
      ...IDivisionName
    }
  }
  ${IDivisionName}
`;

export const IProposalMonetaryBreakdown = gql`
  fragment IProposalMonetaryBreakdown on ProposalMonetaryBreakdown {
    id
    year
    value
    createdAt
    updatedAt
  }
`;

// export const IProposalProratedMonetaryBreakdown = gql`
//   fragment IProposalProratedMonetaryBreakdown on ProposalProratedMonetaryBreakdown {
//     id
//     year
//     value
//     updatedAt
//     isProrated
//   }
// `;

export const IProposalMonetaryBreakdownAggregate = gql`
  fragment IProposalMonetaryBreakdownAggregate on ProposalMonetaryBreakdownAggregate {
    count
    valueSum
    year
    partyId
    stage
    successChance
    owningDivisionId
    party {
      ...IParty
    }
    owningDivision {
      ...IDivisionName
    }
  }
  ${IParty}
  ${IDivisionName}
`;

export const IProposal = gql`
  fragment IProposal on Proposal {
    id
    entityType
    name
    stage
    stageDate
    reviewStatus
    proposalType
    successChance
    receiveDate
    dueDate
    workTypeDetails
    scopeOfWorkDescription
    businessDecisions
    bidProposedValue
    bidFinalValue
    averageHourlyRate
    estimatedTotalHours
    stageReason
    lostReason
    expectedAwardDate
    expectedProjectStartDate
    expectedProjectEndDate
    taxRate
    feesValue
    expensesValue
    numberOfMatureFields
    numberOfGreenFields
    figuresRequired
    travelRequired
    otherTeamMembers
    notes
    createdAt
    updatedAt
    guidelineCode {
      ...IGuidelineCode
    }
    evaluationType {
      ...IEvaluationType
    }
    budgetBasis {
      ...IBudgetBasis
    }
    events {
      ...IProposalEvent
    }
    lastStageChangedEvent {
      ...IProposalEvent
    }
    billingBases {
      ...IBillingBasis
    }
    billingOrigins {
      ...IBillingOrigin
    }
    countries {
      ...ICountry
    }
    deliverables {
      ...IDeliverable
    }
    fields {
      ...IField
    }
    partyContacts {
      ...IPartyContact
    }
    proposalReportTypes {
      ...IProposalReportType
    }
    revenueAreas {
      ...IRevenueArea
    }
    submissionMethods {
      ...ISubmissionMethod
    }
    workTypes {
      ...IWorkType
    }
    competitors {
      ...IProposalCompetitor
    }
    party {
      ...IParty
    }
    leadUser {
      ...IProposalUserRef
    }
    individualsInvolved {
      ...IProposalUserRef
    }
    potentialTeamMembers {
      ...IProposalUserRef
    }
    owningDivision {
      ...IProposalDivisionName
    }
    participatingDivisions {
      ...IProposalDivisionName
    }
    owners {
      ...IProposalUserRef
    }
    reviewers {
      ...IProposalUser
    }
    pendingReviewers {
      ...IProposalUser
    }
    monetaryBreakdowns {
      ...IProposalMonetaryBreakdown
    }
    bidProposedReason
    bestTechnicalEstimateValue
    priceType
    documents {
      id
      proposalFileType
      proposalFileGroup
      originalFilename
      versions {
        version
        createdAt
        file {
          originalFilename
        }
      }
    }
    referenceOpportunity {
      id
      name
    }
    promotedProposals {
      id
      name
    }
    activity
    bonds {
      ...IBondInfo
    }
  }
  ${IBondInfo}
  ${IProposalEvent}
  ${IBillingBasis}
  ${IBillingOrigin}
  ${ICountry}
  ${IDeliverable}
  ${IField}
  ${IPartyContact}
  ${IProposalReportType}
  ${IRevenueArea}
  ${ISubmissionMethod}
  ${IWorkType}
  ${IProposalCompetitor}
  ${IParty}
  ${IGuidelineCode}
  ${IEvaluationType}
  ${IBudgetBasis}
  ${IProposalUserRef}
  ${IProposalDivisionName}
  ${IProposalUser}
  ${IProposalMonetaryBreakdown}
`;

export const IProposalListItem = gql`
  fragment IProposalListItem on Proposal {
    id
    entityType
    name
    party {
      id
      name
      projectSetupClientCode
      createdAt
      divisionOwnership {
        ...IDivisionName
      }
      focalPointUser {
        id
        name
        email
      }
    }
    proposalType
    # entityType
    successChance
    receiveDate
    dueDate
    workTypes {
      id
      name
    }
    submissionMethods {
      id
      name
    }
    stage
    stageDate
    reviewStatus

    # workTypeDetails
    # scopeOfWorkDescription
    # businessDecisions
    bidProposedValue
    # bidFinalValue
    # averageHourlyRate
    # estimatedTotalHours
    # stageReason
    # lostReason
    # expectedAwardDate
    # expectedProjectStartDate
    # expectedProjectEndDate
    # taxRate
    # feesValue
    # expensesValue
    # numberOfMatureFields
    # numberOfGreenFields
    # figuresRequired
    # otherTeamMembers
    # notes
    createdAt
    updatedAt

    owningDivision {
      ...IProposalDivisionName
    }
    leadUser {
      user {
        email
        name
      }
    }
    pendingReviewers {
      user {
        name
      }
    }
    reviewerCount
    approvalCount
    referenceOpportunity {
      id
      name
    }
    promotedProposals {
      id
      name
    }
    activity
  }
  ${IDivisionName}
  ${IProposalDivisionName}
`;

export const ICurrency = gql`
  fragment ICurrency on Currency {
    id
    abbreviation
    description
  }
`;
