import { ProposalStage } from 'graphql/proposals/types/graphql-types';
import { useMemo } from 'react';
import { IProposalData } from 'template/Proposal/ProposalContext';
import { IFieldsDescription } from '../interface';
import { proposalWorkflow } from '../constants/workflow';
import { FieldType } from '../components/ColumnsTemplate/TwoColumnTemplate';

export const useOtherFields = (
  proposalData?: IProposalData,
  proposalStatus?: ProposalStage
): { fields: Array<Array<IFieldsDescription>> } => {
  const workflowTransition = useMemo(() => {
    const { selectedStage } = proposalData || {};
    return proposalWorkflow
      .find((item) => item.state === proposalStatus)
      ?.to.find((item) => item.toState === selectedStage?.id);
  }, [proposalStatus, proposalData]);

  const { askForReason = undefined, askForLoss = undefined } = workflowTransition || {};

  const otherFields = useMemo(() => {
    return [
      [
        {
          id: 'stageReason',
          title: 'Status reason',
          showColapsed: false,
          field: { type: 'textareaAutosize' as FieldType },
          group: 2,
          isDisabled: !askForReason,
        },
        {
          id: 'lostReason',
          title: 'Lost reason',
          showColapsed: false,
          field: { type: 'textareaAutosize' as FieldType },
          group: 2,
          isDisabled: !askForLoss,
        },
        {
          id: 'reviewers',
          title: 'Reviewers',
        },
        {
          id: 'partyId',
          title: 'Party #ID',
        },
        {
          id: 'stageDate',
          title: 'Stage Date',
          showColapsed: false,
          field: { type: 'date' as FieldType },
          group: 2,
        },
      ],
    ];
  }, [askForReason, askForLoss]);

  return {
    fields: otherFields,
  };
};
