import { ClearAllOutlined as ClearAllOutlinedIcon } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ConfirmationButton } from 'components/ConfirmationButton/ConfirmationButton';
import { opportunitiesTableHead } from 'constants/opportunitiesTable';
import paths from 'constants/paths';
import { FC } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useHistory } from 'react-router';
import { useComponentContext } from './ProposalsListViewContext';
import s from './style.module.scss';

import { StickyHeaderTable } from 'components/ui/Table/StickyHeaderTable';
import { DEBOUNCE_TIMEOUT } from 'constants/config';
import { debounce } from 'lodash';
import { ExportToExcel } from '../ExportToExcel/ExportToExcel';

export interface ITableViewProps {
  onReady?: () => void;
  printView?: boolean;
}

export const ProposalsListView: FC<ITableViewProps> = ({ onReady, printView }) => {
  const history = useHistory();

  const {
    totalItems,
    proposals,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterApplied,
    clearAllFilters,
    onProposalSelect,
    filterOptions,
    filterValues,
  } = useComponentContext();

  const gotoNewProposal = () => {
    history.push(paths.client.NEW_OPPORTUNITY);
  };

  return (
    <LoadingOverlay spinner active={loading && !printView} text="Loading your content...">
      <Grid container padding={0} spacing={2} className={s.container}>
        <Grid xs={12}>
          <Grid container padding={0} spacing={2}>
            <Grid xs={12} sm={4} style={{ padding: '16px', marginTop: '16px' }} order={{ xs: 1 }}>
              <ConfirmationButton
                action={gotoNewProposal}
                text="Create New Opportunity"
              ></ConfirmationButton>
            </Grid>

            <Grid xs={12} sm={4} order={{ xs: 3, sm: 2 }}>
              {filterApplied ? (
                <Box display="flex" justifyContent="center" mt={2}>
                  <Button
                    onClick={debounce(clearAllFilters, DEBOUNCE_TIMEOUT)}
                    variant="outlined"
                    size="small"
                    endIcon={<ClearAllOutlinedIcon />}
                  >
                    Clear Filters
                  </Button>
                </Box>
              ) : undefined}
            </Grid>

            <Grid
              xs={12}
              sm={4}
              style={{ display: 'flex', justifyContent: 'end' }}
              order={{ xs: 2, sm: 3 }}
            ></Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Box
            style={{
              borderRadius: '1px',
              boxShadow: '0px 2px 5px #00000030',
              border: '1px solid #00000030',
            }}
          >
            <StickyHeaderTable
              totalItems={totalItems}
              dataCells={proposals}
              headCells={opportunitiesTableHead}
              loadPage={loadPage}
              handleSelect={onProposalSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
              printView={printView}
              initOrderByColumns={pageLoadParams.orderByColumns}
              initPage={pageLoadParams.page}
              paginationSideComponent={
                <ExportToExcel
                  pageLoadParams={pageLoadParams}
                  totalItems={totalItems}
                ></ExportToExcel>
              }
            ></StickyHeaderTable>
          </Box>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};
