import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { StickyHeaderTable } from 'components/ui/Table/StickyHeaderTable';
import { bondsTableHead } from 'constants/bondsTable';
import { useUI } from 'contexts/UiContext';
import { FC, useCallback, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';

import { useHistory } from 'react-router-dom';
import { apolloErrorHandler } from 'utils/apolloErrorHandler';

import paths from 'constants/paths';
import { useBondsTableContext } from 'template/Bonds/BondsContext';

export interface IBondsProps {
  proposalId: string;
}

export const Bonds: FC<IBondsProps> = ({ proposalId }) => {
  const history = useHistory();
  const { addSnackbar } = useUI();
  const {
    totalItems,
    documents,
    loadPage,
    pageLoadParams,
    loading,
    onFilterChange,
    filterOptions,
    filterValues,
    error,
  } = useBondsTableContext();

  useEffect(() => {
    if (!loading && !!error) {
      apolloErrorHandler(addSnackbar!)(error);
    }
  }, [loading, error, addSnackbar]);

  const onBondSelect = useCallback(
    (id: string) => {
      history.push(
        paths.client.PROPOSAL_BID_PERFORMANCE_BOND_VIEW.replace(':id', proposalId).replace(
          ':bondId',
          id
        )
      );
      return true;
    },
    [history, proposalId]
  );

  return (
    <LoadingOverlay spinner active={loading} text="Loading your content...">
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Box mt={2} />
          <Box
            style={{
              borderRadius: '1px',
              boxShadow: '0px 2px 5px #00000030',
              border: '1px solid #00000030',
            }}
          >
            <StickyHeaderTable
              totalItems={totalItems}
              dataCells={documents}
              headCells={bondsTableHead}
              loadPage={loadPage}
              handleSelect={onBondSelect}
              filterOptions={filterOptions}
              filterValues={filterValues}
              onFilterChange={onFilterChange}
              initRowsPerPage={pageLoadParams.rowsPerPage}
              initOrderByColumns={pageLoadParams.orderByColumns}
              initPage={pageLoadParams.page}
            />
          </Box>
        </Grid>
      </Grid>
    </LoadingOverlay>
  );
};
